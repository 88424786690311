function performAutofillRequest(
    formId,
    clientId,
    usePrevious = false,
    checkStatus = false,
    retryCount = 0
) {
    const maxRetries = 5;
    const baseDelay = 1000; // 1 second

    $.ajax({
        url: site_url("clients/forms/autofill"),
        method: "POST",
        data: {
            form_id: formId,
            client_id: clientId,
            use_previous: usePrevious ? 1 : 0,
            check_status: checkStatus ? 1 : 0,
        },
        success: function (response) {
            handleAutofillResponse(response, formId, clientId);
        },
        error: function (xhr, status, error) {
            if (retryCount < maxRetries) {
                const delay = Math.pow(2, retryCount) * baseDelay + Math.random() * 1000;
                console.log(`Request failed. Retrying in ${delay}ms...`);
                setTimeout(() => {
                    performAutofillRequest(
                        formId,
                        clientId,
                        usePrevious,
                        checkStatus,
                        retryCount + 1
                    );
                }, delay);
            } else {
                showError(
                    "An error occurred while autofilling the form. Please refresh the page and try again."
                );

                // Retry anyway!
                setTimeout(() => {
                    performAutofillRequest(
                        formId,
                        clientId,
                        usePrevious,
                        checkStatus,
                        retryCount + 1
                    );
                }, 5 * 1000);
            }
        },
    });
}

function handleAutofillResponse(data, formId, clientId) {
    if (data.status === "generated") {
        fillFormWithData(data.data);
        showCompletionMessage();
        $("#autofill-modal").modal("hide");
    } else if (data.status === "generating" || data.status === "started") {
        showGeneratingMessage(
            data.current_question,
            data.questions_completed,
            data.number_of_questions
        );
        setTimeout(
            () => performAutofillRequest(formId, clientId, true, true),
            5000
        );
    } else if (data.status === "error") {
        showError(data.error || "An unexpected error occurred.");
    } else {
        showError("Unexpected response from server.");
    }
}

$(document).on("click", ".js-autofill-with-ai", function (e) {
    e.preventDefault();
    const usePrevious = $(this).hasClass("js-use-previous");
    const $modal = $("#autofill-modal");
    const formId = $modal.data("form-id");
    const clientId = $modal.data("client-id");

    // Show loading indicator
    $("#autofill-error, #autofill-content").hide();
    $("#autofill-loading").show();
    $(".modal-footer").hide();

    performAutofillRequest(formId, clientId, usePrevious);
});

$(document).on("ready", function () {
    const $modal = $("#autofill-modal");
    const $isGenerating = $modal.find("[name='is_generating']");
    if ($isGenerating.length && parseInt($isGenerating.val()) === 1) {
        showGeneratingMessage();
        performAutofillRequest(
            $modal.data("form-id"),
            $modal.data("client-id"),
            true,
            true
        );
    }
});

function showError(message) {
    $("#autofill-loading, #autofill-content").hide();
    $("#autofill-error").text(message).show();
    $(".modal-footer").show();
}

function showGeneratingMessage(
    currentQuestion,
    questionsCompleted,
    totalQuestions
) {
    $("#autofill-error, #autofill-content").hide();
    const progressPercentage = totalQuestions
        ? Math.round((questionsCompleted / totalQuestions) * 100)
        : 0;

    let message = [`<p>GreenShoots AI is generating responses based on this client's case notes.</p>`];
    if (totalQuestions) {
        message.push(`<p>Progress: ${questionsCompleted} / ${totalQuestions} questions (${progressPercentage}%)</p>`);
    }

    message.push(`<div class="progress mb-3">
          <div class="progress-bar" role="progressbar" style="width: ${progressPercentage}%; min-width: 2em;" aria-valuenow="${progressPercentage}" aria-valuemin="0" aria-valuemax="100">
                ${progressPercentage}%
</div>
      </div>`);

    if (currentQuestion) {
        message.push(`<p>Currently answering: "${currentQuestion}"</p>`);
    }

    message.push(`<p>This can take up to an hour, as we're crunching through all of this client's case history. You can close this tab and return to it later.</p>`);

    $("#autofill-loading").html(message.join("")).show();
    $(".modal-footer").hide();
}

function showCompletionMessage() {
    $("#autofill-loading").hide();
    $("#autofill-content")
        .html(
            "<p>The form has been successfully filled with AI-generated responses.</p>"
        )
        .show();
    $(".modal-footer").show();
}

function fillFormWithData(data) {
    console.log(data, "filling");

    // Iterate through the form fields and fill them with the received data
    $.each(data, function (fieldName, fieldValue) {
        const $field = $('[name="' + fieldName + '"]');
        if ($field.length) {
            if ($field.is(":radio")) {
                $field.filter('[value="' + CSS.escape(fieldValue) + '"]').prop("checked", true);
            } else if ($field.is(":checkbox")) {
                $field.prop("checked", fieldValue === true);
            } else if ($field.is("select")) {
                $field.val(fieldValue).trigger("change");
            } else {
                $field.val(fieldValue).trigger("input");
                autosize.update($field);
            }
            $field.trigger("change");
        }
    });
}
